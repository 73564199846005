import { useState, useContext, useEffect } from "react";
import styled from "styled-components";

import { ModalContext } from "context/Modal";
import { LocalizationContext } from "context/Localization";
import { NotificationsContext } from "context/Notifications";

import { objectToFormData, logFormData, metrixDateToJsDate } from "tools/misc";
// import { defaultPost } from "tools/axios";
import { useAxios } from "tools/axios";
import { objectToXml } from "tools/xml";

// react select onChange returns array for multi, object for single
import Select from "react-select";
import Button from "components/Button";

// Client needs to keep track of the dashboard prder numbering
// so dont setup random numbers like 43443434

// instead
// get current length of the dashboard
// option = length + 1

const AddToDashboard = ({ reportRequest, dashboard, addToDashboard }) => {
  const { defaultPost } = useAxios();
  const { closeModal } = useContext(ModalContext);
  const { mapi18n } = useContext(LocalizationContext);
  const { addNotification } = useContext(NotificationsContext);
  const [type, setType] = useState(null);
  const [waiting, setWaiting] = useState(null);

  const handleSubmit = async () => {
    if (type) {
      try {
        setWaiting("submit");
        addToDashboard(
          reportRequest,
          type,
          "period",
          dashboard ? dashboard.length + 1 : 1
        );
      } catch (err) {
        setWaiting(null);
      }
    } else {
      addNotification("warning", "Missing type");
    }
  };
  const handleFilterChange = (value) => {
    setType(value.value);
  };

  return (
    <Container>
      <Select
        options={[
          { value: "c", label: mapi18n("prf_lbl_cht") },
          { value: "t", label: mapi18n("prf_lbl_tab") },
        ]}
        onChange={handleFilterChange}
      ></Select>
      <ActionRow>
        <Button tertiary onClick={closeModal} label={mapi18n("gen_btn_can")} />
        <Button
          primary
          loading={waiting === "submit"}
          onClick={handleSubmit}
          label={mapi18n("rep_hnt_adddash")}
        />
      </ActionRow>
    </Container>
  );
};

export default AddToDashboard;

const Container = styled.div`
  place-items: center;
  width: 40vw;
`;

const ActionRow = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
`;
