import xml2js from "xml2js";

/**
 * Convert XML string to JS Object
 * @param {string} xml
 */
export const xmlToObject = async (xml) => {
  try {
    const parser = new xml2js.Parser();
    const data = await parser.parseStringPromise(xml);
    return data;
  } catch (err) {
    console.error("XML Parsing Error Occurred: " + err);
    return err;
  }
};

/**
 * Convert JS Object to XML String
 * @param {object} object
 */
export const objectToXml = (object) => {
  try {
    const builder = new xml2js.Builder({ renderOpts: { pretty: false } });
    const data = builder.buildObject(object);
    return data;
  } catch (err) {
    console.error("XML Building Error Occurred: " + err);
    return err;
  }
};
