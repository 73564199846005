import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
:root {
    --color-black: #000;
    --color-gray-0: rgba(0,0,0,0.05);
    --color-gray-1: rgba(0,0,0,0.1);
    --color-gray-2: rgba(0,0,0,0.2);
    --color-gray-3: rgba(0,0,0,0.3);
    --color-gray-4: rgba(0,0,0,0.4);
    --color-gray-5: rgba(0,0,0,0.5);
    --color-gray-6: rgba(0,0,0,0.6);
    --color-gray-7: rgba(0,0,0,0.7);
    --color-gray-8: rgba(0,0,0,0.8);
    --color-gray-9: rgba(0,0,0,0.9);
    --color-white: #fff;

    --color-bluemetrix-blue-0: #2B4281;
    --color-bluemetrix-blue-1: #DFE6F1;
    --color-bluemetrix-blue-2: #F8FAFF;
    --color-bluemetrix-blue-3: #5B78C8;


    --color-red: #FF0000;
    --color-amber: #FBB70A;


}
* {
    font-family: "Noto Sans", sans-serif;  
}
html {
    --color-primary: var(--color-bluemetrix-blue-0);

    --color-button-primary: var(--color-bluemetrix-blue-0);
    --color-button-contrast: var(--color-white);
    --color-hover-opacity: var(--color-gray-0);

    --color-paper: var(--color-white);
    --color-paper-rough: #F0F0F0;
    --color-paper-rough-highlight: #FAFAFA;
    --color-background: var(--color-bluemetrix-blue-1);

    --color-label-primary: var(--color-bluemetrix-blue-0);

    --color-warning: var(--color-amber);
    --color-error: var(--color-red);
    --color-primary: var(--color-bluemetrix-blue-0);

    --color-text-default: black;
    --color-text-secondary: #707070;

    --color-dashboard: var(--color-bluemetrix-blue-3);

    --padding-button-default: 0.5rem 1rem;
    --margin-button-default: 0.5rem;
    --margin-button-icon-default: 0 0.5rem 0 0;

    --margin-input-default: 0.5rem;
    --padding-input-default: 0.5rem 1rem;

    --padding-notification-default: 0.5rem 1rem;
    --margin-notification-default: 0.5rem;
    --margin-notification-icon-default: 0 0.5rem 0 0;
    --color-notification-contrast: var(--color-white);

    --box-shadow-1: 2px 2px 2px rgba(43, 66, 129, 0.25);
    --box-shadow-2: 5px 5px 5px rgba(43, 66, 129, 0.25);
    --box-shadow-3: 8px 8px 8px rgba(43, 66, 129, 0.25);

    --color-calendar-unselected: var(--color-gray-1);
    --color-calendar-outside-month: var(--color-gray-4);
    --color-calendar-in-selecting-range: var(--color-bluemetrix-blue-1);
    --color-calendar-in-range: var(--color-bluemetrix-blue-0);

    --tab-width: 240px;
    --tab-height: 45px;

    --z-index-notifications: 10000;
    --z-index-modal: 1000;
    --z-index-header: 50;
    --z-index-date-picker: 200;

    --font-size-1: 34px;
    --font-size-2: 28px;
    --font-size-3: 22px;
    --font-size-4: 18px;
    --font-size-5: 16px;
    --font-size-6: 14px;
    --font-size-7: 12px;
    

    background-color: var(--color-bluemetrix-blue-2);
    font-family: "Noto Sans", sans-serif;
    user-select: none;
}

`;

export default GlobalStyles;
