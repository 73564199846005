import { useState, useContext, useEffect } from "react";
import styled from "styled-components";

import { ModalContext } from "context/Modal";
import { LocalizationContext } from "context/Localization";
import { NotificationsContext } from "context/Notifications";

// react select onChange returns array for multi, object for single
import Select from "react-select";
import Button from "components/Button";

// uses combination of parent options and drilldownoptions to make a report
const DrilldownReport = ({
  parentOptions,
  drilldownOptions,
  getReport,
  optionalFields,
}) => {
  const { closeModal } = useContext(ModalContext);
  const { mapi18n } = useContext(LocalizationContext);
  const { addNotification } = useContext(NotificationsContext);
  const [selected, setSelected] = useState();
  const [waiting, setWaiting] = useState();
  const [options, _] = useState(
    drilldownOptions.map((dd) => ({ value: dd.$, label: dd.$.value }))
  );

  const handleSubmit = async () => {
    if (selected) {
      try {
        setWaiting("submit");

        let parentOptionsCopy = { ...parentOptions };
        if (parentOptionsCopy["search_string"]) {
          delete parentOptionsCopy["search_string"];
        }
        await getReport(
          { ...parentOptionsCopy, ...selected },
          null,
          optionalFields
        );
        closeModal();
      } catch (err) {
        addNotification("error", "An error occurred");
        setWaiting(null);
      }
    } else {
      addNotification("warning", "Select option");
    }
  };

  const handleFilterChange = (value) => {
    setSelected(value.value);
  };

  return (
    <Container>
      <Select options={options} onChange={handleFilterChange}></Select>
      <ActionRow>
        <Button tertiary label={mapi18n("gen_btn_can")} onClick={closeModal} />
        <Button
          primary
          loading={waiting === "submit"}
          label={mapi18n("prf_lbl_cnrep")}
          onClick={handleSubmit}
        />
      </ActionRow>
    </Container>
  );
};

export default DrilldownReport;

const Container = styled.div`
  place-items: center;
  width: 40vw;
`;

const ActionRow = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
`;
