import { useState, useEffect, createContext, useMemo } from "react";

export const LocalizationContext = createContext();
const { Provider } = LocalizationContext;

export const LocalizationProvider = ({ children }) => {
  const [interfaceMapping, setInterfaceMapping] = useState(null);
  const [availableEncodings, setAvailableEncodings] = useState(null);
  const [availableLanguages, setAvailableLanguages] = useState(null);
  const [language, setLanguage] = useState("en_IE");

  const mapi18n = (id) => {
    let element = interfaceMapping.find((el) => el.id === id);
    if (element) {
      return element.value;
    } else {
      return "i18n_error";
    }
  };

  const value = useMemo(
    () => ({
      interfaceMapping,
      setInterfaceMapping,
      availableEncodings,
      setAvailableEncodings,
      availableLanguages,
      setAvailableLanguages,
      language,
      setLanguage,
      mapi18n,
    }),
    [language, interfaceMapping, availableEncodings, availableLanguages]
  );

  return <Provider value={value}>{children}</Provider>;
};

/*
InterfaceContext
- setInterfaceMapping
- mapi18n()
- setLanguage
- langauge
*/
