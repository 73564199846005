import "react-app-polyfill/ie11";
import "global/globals";
import GlobalStyles from "styles/globalStyles";
import "styles/root.css";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import { LocalizationProvider } from "context/Localization";
import { NotificationsProvider } from "context/Notifications";

ReactDOM.render(
  <>
    <GlobalStyles />
    <NotificationsProvider>
      <LocalizationProvider>
        <App />
      </LocalizationProvider>
    </NotificationsProvider>
  </>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
