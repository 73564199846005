// context.js

import { useState, createContext, useMemo } from "react";

export const ModalContext = createContext();
const { Provider } = ModalContext;
export const ModalInternalContext = createContext();
const InternalProvider = ModalInternalContext.Provider;

export const ModalProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState(null);
  const [modalSubtitle, setModalSubtitle] = useState(null);
  const [modalContent, setModalContent] = useState(null);

  /*
  const openModal = (content, title, subtitle) => {
    setModalContent(content);
    setModalTitle(title);
    if (subtitle) {
      setModalSubtitle(subtitle);
    }
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen();
    setModalContent(null);
    setModalTitle(null);
    setModalSubtitle(null);
  };

  const value = {
    isOpen,
    openModal,
    closeModal,
    modalTitle,
    modalSubtitle,
    modalContent,
  };
  */
  const openModal = (content, title, subtitle) => {
    setModalContent(() => content);
    setModalTitle(() => title);
    if (subtitle) {
      setModalSubtitle(() => subtitle);
    }
    setIsOpen(() => true);
  };

  const closeModal = () => {
    setIsOpen(() => false);
    setModalContent(() => null);
    setModalTitle(() => null);
    setModalSubtitle(() => null);
  };

  const externalValue = useMemo(
    () => ({
      openModal,
      closeModal,
    }),
    []
  );

  const internalValue = {
    isOpen,
    modalTitle,
    modalSubtitle,
    modalContent,
  };

  return (
    <Provider value={externalValue}>
      <InternalProvider value={internalValue}>{children}</InternalProvider>
    </Provider>
  );
};
