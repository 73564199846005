import { useState, useContext } from "react";
import styled from "styled-components";
import logo from "../logo2.png";

import Button from "components/Button";
import Input from "components/Input";

const LoginPage = ({ login }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleSubmit = async (e) => {
    // todo: validation
    login(username, password);
  };

  return (
    <Page>
      <LoginCard>
        <div>
          <Logo src={logo} alt="logo" />
        </div>
        <div>
          <div>
            <Input
              label="username"
              onChange={handleUsernameChange}
              value={username}
            />
            <Input
              label="password"
              onChange={handlePasswordChange}
              value={password}
              type="password"
            />
            <Button primary label="Login" onClick={handleSubmit} />
          </div>
        </div>
      </LoginCard>
    </Page>
  );
};

export default LoginPage;

const Page = styled.div`
  width: 100%;
  height: 100vh;
  background-color: var(--color-background);
  display: grid;
  place-items: center;
`;

const LoginCard = styled.div`
  width: 720px;
  height: 480px;
  background-color: var(--color-paper);
  box-shadow: var(--box-shadow-1);
  display: flex;

  > div:first-child {
    width: 45%;
    height: 100%;
    display: grid;
    place-items: center;
    background-color: var(--color-primary);
  }
  > div:nth-child(2) {
    width: 55%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    > div {
      display: flex;
      padding: 1rem;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      height: 50%;
    }
  }
`;

const Logo = styled.img`
  max-height: 40px;
`;
